// Assets
import phone from '../assets/phone.svg'
import mail from '../assets/mail.svg'
import directions from '../assets/directions.svg'
import { NavLink } from 'react-router-dom'


const Footer = () => {
    
    function getCurrentYear () {
        return new Date().getFullYear()
    }

    return (
        <footer className="my-5 pt-5 border-top border-dark ">
            <div className="container text-center">

                <div className="row my-5 pb-5 justify-content-start">

                    <div className="col-12 col-sm-4">
                        <p className="qs-footer-header ps-4 fs-6">QUICKSERVE</p> 
                        <ul>
                            <NavLink to="about" className=" nav-link fs-6"> About Us </NavLink>
                            <NavLink to="contact" className=" nav-link fs-6"> Contact </NavLink>
                            <NavLink to="privacypolicy" className=" nav-link fs-6"> Privacy Policy </NavLink>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                        <p className="qs-footer-header ps-4 fs-6">SERVICES</p> 
                        <ul>
                            <NavLink to="services" className=" nav-link fs-6">Ready-To-Drink</NavLink>
                            <NavLink to="/services" className=" nav-link fs-6"> Ready-To-Pour </NavLink>
                            <NavLink to="services" className=" nav-link fs-6"> Recipe Formulation </NavLink>
                            <NavLink to="services" className=" nav-link fs-6"> Events </NavLink>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-4">
                        <p className="qs-footer-header ps-4">CONTACT</p> 
                        <ul >
                            <li className="nav-link"> <span><img src={phone} alt="" /></span> 310-981-3388</li>
                            <li className="nav-link"> <span><img src={mail} alt="" /></span> sales@quickservecocktails.com</li>
                            <li className="nav-link"> <span><img src={directions} alt="" /></span> PO Box 24996 LA CA 90024</li>
                        </ul>
                    </div>

                </div>

                <p className="text-center my-5 pt-5"><span className="text-secondary" >&#169;</span> {getCurrentYear()} <span className="fw-bold text-secondary">QuickServe Cocktails, LLC</span>. All Rights Reserved</p>


            </div>
        </footer>
    )
}

export default Footer